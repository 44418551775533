//map
//変数定義
let click_change = false;
let marker = []; // marker class
let infoWindow = [];
let opend = -1;
let map;
let bounds;
let accordion_downed;
let targetLocationMarker;  // map click marker
let polygon_array;

const isSmall = () => {
  return Foundation.MediaQuery._getCurrentSize() === "small";
};

const isMedium = () => {
  return Foundation.MediaQuery._getCurrentSize() === "medium";
};

const isLarge = () => {
  return Foundation.MediaQuery._getCurrentSize() === "large";
};

const isXLarge = () => {
  return Foundation.MediaQuery._getCurrentSize() === "xlarge";
};

const isXXLarge = () => {
  return Foundation.MediaQuery._getCurrentSize() === "xxlarge";
};

//TOP画面で利用するマップの初期化
window.initMapModalOnly = () => {
  const url = new URL(location.href);
  let distance_id = parseInt(url.searchParams.get("search[distance_id]"));

  // TODO: この変数の扱いをどうするか
  bounds = new google.maps.LatLngBounds();
  map_modal = new google.maps.Map(
    document.getElementById('map_modal'),{
      zoom: mapZoom(distance_id, [], {lat: 1.2831005, lng: 103.8436753}),
      center: {lat: 1.2831005, lng: 103.8436753},
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scaleControl: true,
      zoomControlOptions:{
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }
  );
  // change map panning and scrolling behavior of a map when viewed on a mobile device.
  setGestureHandling(map_modal);

  // add map click marker
  addMapClickListener(map_modal);


  // draw location outline
  drawLoationShapes(map_modal, []);

  // listener
  const listener_modal = google.maps.event.addListenerOnce(map_modal,'idle', function() {
    google.maps.event.removeListener(listener_modal);
  });

  let before_size = Foundation.MediaQuery._getCurrentSize();
  google.maps.event.addDomListener(window, 'resize', function() {
    if (before_size != Foundation.MediaQuery._getCurrentSize()) {
      change_map(map_modal);
      setGestureHandling(map_modal);
    }
    before_size = Foundation.MediaQuery._getCurrentSize();
  });
}


window.initMap = () => {
  // JS API is loaded and available
  const url = new URL(location.href);
  let distance_id = parseInt(url.searchParams.get("search[distance_id]"));
  // TODO: この変数の扱いをどうするか
  bounds = new google.maps.LatLngBounds();
  map_main = new google.maps.Map(
    document.getElementById((isMedium() || isSmall()) ? 'map_small': 'map'),{
      zoom: mapZoom(distance_id, g_markerData, g_iscCnterLatLngDefault),
      center: g_centerLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      fullscreenControl: true,
      fullscreenControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
      scaleControl: true,
      zoomControlOptions:{
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
    }
  );
  map_modal = new google.maps.Map(
    document.getElementById('map_modal'),{
      zoom: mapZoom(distance_id, g_markerData, g_iscCnterLatLngDefault),
      center: g_centerLatLng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      fullscreenControl: false,
      streetViewControl: false,
      scaleControl: true,
      zoomControlOptions:{
        position: google.maps.ControlPosition.RIGHT_BOTTOM,
      },
    }
  );

  // change map panning and scrolling behavior of a map when viewed on a mobile device.
  setGestureHandling(map_main);
  setGestureHandling(map_modal);

  // add map click marker
  addMapClickListener(map_main);
  addMapClickListener(map_modal);

  // draw Center Circle
  if (distance_id){
    drawCenterCircle(map_main, markerData, iscCnterLatLngDefault);
  }

  // draw location outline
  drawLoationShapes(map_main, g_location_shapes);
  drawLoationShapes(map_modal, g_location_shapes);

  // draw shops marker
  drawMarker(map_main, g_markerData);

  adjustZoom(map_main, g_markerData);
  let listener = google.maps.event.addListenerOnce(map_main,'idle', function() {
    adjustZoom(map_main, g_markerData);
    google.maps.event.removeListener(listener);
  });

  let listener_modal = google.maps.event.addListenerOnce(map_modal,'idle', function() {
    google.maps.event.removeListener(listener_modal);
  });

  let before_size = Foundation.MediaQuery._getCurrentSize();
  google.maps.event.addDomListener(window, 'resize', function() {
    if (before_size != Foundation.MediaQuery._getCurrentSize()) {
      change_map(map_main);
      change_map(map_modal);
      setGestureHandling(map_main);
      setGestureHandling(map_modal);
    }
    before_size = Foundation.MediaQuery._getCurrentSize();
  });
}


const mapZoom = (distance_id, markerData, iscCnterLatLngDefault) => {
  let zoom;
  if(markerData.length === 0 && iscCnterLatLngDefault){
    zoom = 12;
  } else if(!distance_id) {
    zoom = 13;
  } else {
    switch (distance_id) {
      case 1: zoom = 18; break;
      case 2: zoom = 17; break;
      case 3: zoom = 16; break;
      case 4: zoom = 16; break;
      case 5: zoom = 15; break;
      case 6: zoom = 15; break;
      default: zoom = 15; break;
    }
  }
  return zoom;
}


const setGestureHandling = (map) => {
  if (isMedium() || isSmall()) {
    //googlemapの設定 初期値。表示しているページがスクロールできる場合はcooperative、スクロールできない場合はgreedyが適用される。
    //通常はこれを使う
    map.set('gestureHandling', 'auto');
  } else {
    //googlemapの設定 旧バージョンの仕様。一本指でドラッグ操作ができる。
    map.set('gestureHandling', 'greedy');
  }
}


// Map Click Listener
const addMapClickListener = (map) => {
  map.addListener('click', (e) => {
    if(typeof before_marker !== 'undefined') {
      before_marker.setMap(null);
    }

    if(typeof before_window !== 'undefined') {
      before_window.close(map);
    }

    // make marker
    targetLocationMarker = new google.maps.Marker({
      position: e.latLng,
      title: 'You can search shops form here',
      map: map,
    });

    // open info window
    hereSearchWindow = searchInfoWindow(e.latLng);
    targetLocationMarker.addListener('click', function () {
      hereSearchWindow.open(map, targetLocationMarker);
    });

    // remove marker
    targetLocationMarker.addListener('dblclick', function () {
      targetLocationMarker.setMap(null);
    });

    before_window = hereSearchWindow;
    before_marker = targetLocationMarker;

    click_change = true; //modal

    let request = new XMLHttpRequest();
    request.open('GET', `/${locale}/location_shapes?search[lat]=${e.latLng.lat()}&search[lng]=${e.latLng.lng()}`, true);
    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        const response = JSON.parse(request.responseText)
        if(polygon_array){
          polygon_array.forEach(function(polygon) {
            polygon.setMap(null);
          });
        }
  
        //スマホモーダル用
        const planningArea = document.querySelector("#modal_search_planning_area_id")
        planningArea.value = response[0]["planning_area_id"]
        triggerEvent(planningArea, 'change')
        //select要素追加
        var tmps = subzone.filter(v=> v[3] == response[0]["planning_area_id"])
        var select_groups = ""
        var selects = ""
        tmps.forEach(v=>{
            select_groups = `<optgroup label="${v[2]}">`
            selects += `<option value="${v[1]}">${v[0]}</option>`
        })
        var complete_select = select_groups + selects + "</optgroup>"
        $("#modal_search_subzone_id > optgroup").remove()
        $("#modal_search_subzone_id").append(complete_select)
        // TODO: select2 のパラメータにplaceholderがない。エラーになるはず。
        $("#modal_search_subzone_id").select2({allowClear: true});
  
        // TODO: location district planning_area がぐじゃぐちゃ？
        $("#location_searchbox").val(response[0]["planning_area_id"])
        $("#location_searchbox").trigger('change');
        $("#district_searchbox").val(response[0]["id"])
        $("#district_searchbox").trigger('change');
        polygon_array = drawLoationShapes(map, response[1]);
      }
    }
    request.send();
    // このエリアを検索表示(PCのみ)
    if (document.getElementById("searchBtn") == null && !navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/i)){
      const ingressButtonDiv = document.createElement("div");
      ingressButtonDiv.id = "searchBtn"
      new ingressControl(ingressButtonDiv, map);
      ingressButtonDiv.index = 1;
      map.controls[google.maps.ControlPosition.TOP].push(ingressButtonDiv);
    }
  });
}

// draw center circle
const drawCenterCircle = (map, markerData, iscCnterLatLngDefault) => {
  if (markerData.length === 0 || iscCnterLatLngDefault || !isDistanseSearch()) return;
  let here = new google.maps.Marker({
    position: centerLatLng,
    map: map,
    title: 'Here',
  });
  let markerLatLng = new google.maps.LatLng(centerLatLng);

  here.addListener('click', function() {
    searchInfoWindow(markerLatLng, false).open(map, here);
  });

  let cityCircle = new google.maps.Circle({
    strokeColor: '#ffa500',
    strokeWeight: 1,
    fillColor: '#ffa500',
    fillOpacity: 0.1,
    map: map,
    center: centerLatLng  ,
    radius: radius(),
    clickable: false
  });
}

const triggerEvent = (element, event) => {
  if (document.createEvent) {
      // IE以外
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent(event, true, true ); // event type, bubbling, cancelable
      return element.dispatchEvent(evt);
  } else {
      // IE
      let evt = document.createEventObject();
      return element.fireEvent("on"+event, evt)
  }
}

const isDistanseSearch = () =>{
  const url = new URL(location.href);
  let distance_id = parseInt(url.searchParams.get("search[distance_id]"));
  let famous_spot_id = parseInt(url.searchParams.get("search[famous_spot_id]"));
  let staying = url.searchParams.get("search[staying]");
  let stations = url.searchParams.get("search[stations]");
  let bus = url.searchParams.get("search[bus]");
  let lat = parseInt(url.searchParams.get("search[lat]"));
  let lng = parseInt(url.searchParams.get("search[lng]"));
  if (distance_id && (famous_spot_id || staying || stations || bus || (lat && lng))){
      return true;
  } else {
      return false;
  }
}

// draw area outline
const drawLoationShapes = (map, location_shapes) =>{
  let polygon_array=[];
  // TODO: polygons, polygon の変数は？
  location_shapes.forEach(function(polygons){
    polygons.forEach(function(polygon){
      let poly = new google.maps.Polygon({
        map: map,
        paths: polygon,
        clickable: false,
        strokeWeight: 1,
        strokeColor: "#ffa500",
        // strokeOpacity: 0.35,
        fillColor: "#ffa500",
        fillOpacity: 0.1
      });
      polygon_array.push(poly);
    });
  });
  return polygon_array;
}


// draw shop marker
const drawMarker = (map, markerData) =>{
  for (var i = 0; i < markerData.length; i++) {
    markerLatLng = new google.maps.LatLng({lat: Number(markerData[i][0]['lat']), lng: Number(markerData[i][0]['lng'])});
    bounds.extend(markerLatLng);
    marker[i] = new google.maps.Marker({
      position: markerLatLng,
      map: map,
      icon: icon(markerData[i][0]),
    });

    if(markerData[i][0]["listed"] == true){
      contents=[];
      for (var j = 0; j < markerData[i].length; j++) {
        contents.push(infoWindowContent2(markerData[i][j]));
        shop_marker_index[markerData[i][j]['shop_id']] = i;
      }
      infoWindow[i] = new google.maps.InfoWindow({
          content: `${infoWindowContent(markerData[i][0])} ${contents.join('')}`
      });
      // listed_count++;
    } else {
      infoWindow[i] = new google.maps.InfoWindow({
          content: infoWindowContent(markerData[i][0])
      });
    }
    markerEvent(i);
  }
}

const markerEvent = (i) => {
  marker[i].addListener('click', function() {
    //todoここ必要か後で確認（ローカルだとアイコン画像がでないので確認できない）
    infoWindowOpenClose(i);
  });
}


window.infoWindowOpenClose = (i) => {
  infoWindow[i].open(map, marker[i]);
  if (opend != -1 && opend != i ){
    infoWindow[opend].close(map);
  }
  opend=i;
}

const ingressControl = (buttonDiv, map) => {
  const buttonUI = document.createElement("div");
  buttonUI.style.backgroundColor = "rgb(255, 255, 255)";
  buttonUI.style.border = "0";
  buttonUI.style.boxShadow = "0 2px 5px -1px rgba(0, 0, 0, 0.3)";
  buttonUI.style.cursor = "pointer";
  buttonUI.style.margin = '110px auto 0 auto';
  buttonUI.style.padding = "5px 30px";
  buttonUI.style.borderRadius = "21px";
  if (isMedium() || isSmall()) {
    buttonUI.style.margin = '8px auto 0 auto';
  } else {
    buttonUI.style.margin = '110px auto 0 auto';
  }
  buttonUI.style.color = "#424242";
  buttonUI.style.fontSize = "14px";
  buttonUI.style.fontWeight = "500";
  buttonUI.style.textAlign = "center";
  buttonUI.title = serachTitle;
  buttonUI.innerHTML = searchTag;
  buttonDiv.style.padding = "5px";
  buttonDiv.appendChild(buttonUI);
  google.maps.event.addDomListener(buttonUI, "click", function() {
    document.querySelector("#modal_search_planning_area_form").submit();
  });
}


const infoWindowContent = (shop) => {
  if(shop['listed'] == false) {
    return `<a href="/${locale}/shops/${shop['shop_id']}">${shop["name_"+ locale]}</a>`
  } else {
    return '';
  }
}


const infoWindowContent2 = (shop) => {
  return `<div class="map-marker"><div class="map-marker-no"></div>` +
    `<div class="map-marker-name"><a href="#" onclick="scrollById('#shop${shop['shop_id']}'); return false;" data-turbolinks="false">${shop['name_' + locale]}</a></div></div>`;
}



const icon = (shop) =>{
  let host = location.hostname ;
  if((shop["no"] >= 0 && shop["no"] <= 10) && shop["listed"] == true){
    if(shop['food_court_id']) {
      return {
        // url: `https://maps.google.com/mapfiles/kml/paddle/${shop["title"]}.png`,
        // url: `https://maps.google.com/mapfiles/kml/paddle/${shop["no"]}.png`,
        url: `https://pubimg.jointhawker.com/assets/images/common/map_no/${shop["title"]}_1.png`,
        scaledSize: new google.maps.Size(40, 30)
      }
    } else {
      return {
        // url: `https://maps.google.com/mapfiles/kml/paddle/${shop["title"]}.png`,
        // url: `https://maps.google.com/mapfiles/kml/pal3/icon${shop["no"]-1}.png`,
        url: `https://pubimg.jointhawker.com/assets/images/common/map_no/${shop["title"]}_4.png`,
        scaledSize: new google.maps.Size(40, 30)
      }
    }
  } else {
    if(shop['food_court_id']){
      return {
        url: 'https://maps.google.com/mapfiles/kml/paddle/red-circle-lv.png',
        scaledSize: new google.maps.Size(11, 11)
      }
    } else {
      return{
        url: 'https://maps.google.com/mapfiles/kml/paddle/blu-circle-lv.png',
        scaledSize: new google.maps.Size(11, 11)
      }
    }
  }
}


const adjustZoom = (map, markerData) => {
  if( markerData.length > 0) {
      map.fitBounds(bounds);
      map.panToBounds(bounds);
      if (map.getZoom() > 16) {
          map.setZoom(16);
      } else if (map.getZoom() <= 12) {
          map.setZoom(12);
      }
  }
}


const change_map = (map) =>{
  if (document.getElementById('mapDefault')) {
    return false;
  } 

  var map_dom = document.getElementById('map');
  var map_small_dom = document.getElementById('map_small');
  var map_modal_dom = document.getElementById('map_modal');


  if (isMedium() || isSmall()) {
    if(map_dom){
      var nodes = map_dom.children;
      for (var i = 0; i < nodes.length; i++) {
        map_small_dom.appendChild(nodes[i]);
      }  
    }
  } else {
    if(map_small_dom){
      var nodes = map_small_dom.children;
      for (var i = 0; i < nodes.length; i++) {
        map_dom.appendChild(nodes[i]);
      }
    }
  }

  var nodes_modal = map_modal_dom.children;
  for (var i = 0; i < nodes_modal.length; i++) {
    if(map_modal_dom){
      map_modal_dom.appendChild(nodes_modal[i]);
    }
  }

}


///////////////////////////////////////////////////////////////////
const modal_area_change = () =>{
   if(click_change){
     click_change = false;
     return;
   }
   var planning_area_id = $("#modal_search_planning_area_id").val()
   var sub_zone_id = $("#modal_search_subzone_id").val()
   $.getJSON(`/${locale}/location_shapes?search[planning_area_id]=${planning_area_id}&search[id]=${sub_zone_id}`, function(response) {
        if(polygon_array){
            polygon_array.forEach(function(polygon) {
                polygon.setMap(null);
            });
        }
        //mapへ描画
        map.panTo(new google.maps.LatLng(response[0].planning_lat, response[0].planning_lng))
        polygon_array = drawLoationShapes(map, response[1]);
        //select修正
        var tmps = subzone.filter(v=> v[3] == planning_area_id)
        var select_groups = ""
        var selects = ""
        tmps.forEach(v=>{
          select_groups = `<optgroup label="${v[2]}">`
          selects += `<option value="${v[1]}">${v[0]}</option>`
        })
        var complete_select = select_groups + selects + "</optgroup>"
        $("#modal_search_subzone_id > optgroup").remove()
        $("#modal_search_subzone_id").append(complete_select)
        $("#modal_search_subzone_id").select2({allowClear: true});
   });
}
const modal_subzone_change = () =>{
   var planning_area_id = $("#modal_search_planning_area_id").val()
   var sub_zone_id = $("#modal_search_subzone_id").val()
   $.getJSON(`/${locale}/location_shapes?search[planning_area_id]=${planning_area_id}&search[id]=${sub_zone_id}`, function(response) {
        if(polygon_array){
            polygon_array.forEach(function(polygon) {
                polygon.setMap(null);
            });
        }
        //mapへ描画
        map.panTo(new google.maps.LatLng(response[0].planning_lat, response[0].planning_lng))
        polygon_array = drawLoationShapes(map, response[1]);
   });
}


const removeTargetLocationMarker = () =>{
  targetLocationMarker.setMap(null);
}

/////////////////////////////////////////////////////////////////
const searchInfoWindow = (latLng, ableToDelete = true) => {
  //<%# p = request.query_parameters.except(:location,:lat,:lng,:locale, :distance, :famous_spot, :staying, :bus, :stations, :district).to_param %>
  content = "Search from here in " +
    ` <a href='${location.pathname}?search_type=here&distance=1&lat=${latLng.lat()}&lng=${latLng.lng()}'>200m</a>` +
    ` <a href='${location.pathname}?search_type=here&distance=2&lat=${latLng.lat()}&lng=${latLng.lng()}'>400m</a>` +
    ` <a href='${location.pathname}?search_type=here&distance=3&lat=${latLng.lat()}&lng=${latLng.lng()}'>800m</a>` +
    ` <a href='${location.pathname}?search_type=here&distance=4&lat=${latLng.lat()}&lng=${latLng.lng()}'>1km</a>` +
    ` <a href='${location.pathname}?search_type=here&distance=5&lat=${latLng.lat()}&lng=${latLng.lng()}'>1.5km</a>` +
    ` <a href='${location.pathname}?search_type=here&distance=6&lat=${latLng.lat()}&lng=${latLng.lng()}'>2km</a>`;

  if(ableToDelete == true){
    content += "<br/><br/><a href='#' onclick='removeTargetLocationMarker(); return false;'>delete me</a>";
  }
  searchWindow = new google.maps.InfoWindow({
      content: content
  });
  return searchWindow;
}

const scrollToMap = (id) => {
  if(isSmall() || isMedium()){
      scrollById(id);
  }
}

// scroll to shop cassette
window.scrollById = (id) => {
  let anchor = document.querySelector(id);
  if(document.fullScreen||document.mozFullScreen||document.webkitIsFullScreen){
    //scroll.animateScroll(anchor, null, {speed: 1, speedAsDuration: true});
    anchor.scrollIntoView({behavior: "smooth"})
  } else {
    anchor.scrollIntoView({behavior: "smooth"})
    //scroll.animateScroll(anchor);
  }
}

const radius = () =>{
    const url = new URL(location.href);
    let distance_id = parseInt(url.searchParams.get("search[distance_id]"));
    if(!distance_id) {
        return 800;
    }
    var radius;
    switch (distance_id) {
        case 1: radius = 200; break;
        case 2: radius = 400; break;
        case 3: radius = 800; break;
        case 4: radius = 1000; break;
        case 5: radius = 1500; break;
        case 6: radius = 2000; break;
        default: radius = 800; break;
    }
    return radius;
}
